<template functional>
  <div class="product-class">
    <!-- <v-tooltip top v-if="props.pClass.iconSource">
      <template v-slot:activator="{ on, attrs }">
        <div class="mr-1 product-class" v-bind="attrs" v-on="on">
          <img
            aria-hidden
            :src="props.pClass.iconSource"
            :alt="props.pClass.name"
            :title="props.pClass.name"
            class="product-class-icon"
          />
        </div>
      </template>
      <span>{{ props.pClass.name }}</span>
    </v-tooltip> -->
    <v-chip color="secondary" outlined> {{ props.pClass.name }}</v-chip>
  </div>
</template>
<style lang="scss">
.product-class {
  div {
    width: fit-content;
  }
  .product-class-icon {
    min-width: 22px;
    height: auto;
  }
  .v-chip {
    font-size: 16px;
    padding: 9px 20px 8px 20px;
  }
}
</style>
<script>
export default {
  name: "ProductClass",
  props: {
    pClass: { type: Object },
    height: { type: Number, default: 22 },
    width: { type: Number, default: 22 }
  }
};
</script>

<template>
  <div class="product-images">
    <v-row no-gutters>
      <v-col
        :cols="$vuetify.breakpoint.xs || images.length < 2 ? '12' : '9'"
        order="2"
        class="d-flex justify-center pl-3"
      >
        <div class="image-block-wrapper">
          <img
            class="image-mobile"
            v-if="$vuetify.breakpoint.xs"
            :src="selectedImage.medium"
            :alt="'Immagine prodotto ' + name"
            @click.stop="dialog = true"
            onerror="this.onerror=null;this.src='/no-icon.png'"
          />
          <zoom-on-hover
            v-else
            :scale="1.5"
            :img-normal="selectedImage.medium"
            :img-zoom="selectedImage.large"
          ></zoom-on-hover>
        </div>
      </v-col>

      <v-col
        cols="3"
        order="1"
        class="d-sm-flex d-none justify-center thumb-wrapper"
        v-if="images.length > 1"
      >
        <div class="swiper-button-next" slot="button-next"></div>
        <swiper :options="swiperOptionThumbs" class="swiper" ref="swiperThumb">
          <swiper-slide v-for="media in images" :key="media.medium">
            <img
              :key="'img_' + media.medium"
              :src="media.small"
              :alt="'Anteprima immagine prodotto ' + name"
              :class="{ selected: selectedImage == media }"
              @click="selectedImage = media"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" slot="button-prev"></div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="zoom-dialog"
    >
      <div class="zoom-dialog-content mt-4">
        <v-row no-gutters justify="center">
          <v-btn
            color="primary"
            large
            @click.stop="dialog = false"
            width="250"
            depressed
            >{{ $t("common.close") }}</v-btn
          >
        </v-row>
        <pinch-zoom height="100%" class="mt-6">
          <img :src="selectedImage.large" />
        </pinch-zoom>
      </div>
      <div
        class="d-flex flex-row zoom-swiper-wrapper mt-6"
        v-if="images.length > 1"
      >
        <swiper :options="swiperOptionThumbs" class="swiper">
          <swiper-slide v-for="media in images" :key="media.medium">
            <img
              :key="'img_' + media.medium"
              :src="media.small"
              :alt="'Anteprima immagine prodotto ' + name"
              :class="{ selected: selectedImage == media }"
              @click="selectedImage = media"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </swiper-slide>
        </swiper>
      </div>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.product-images {
  .image-block-wrapper {
    width: 360px;
    text-align: center;
    .image-mobile {
      max-width: 100%;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    background: transparent;
    top: 25px;
    &::after {
      font-size: 40px;
      background-color: transparent;
      color: var(--v-secondary-lighten1);
      padding: 0;
    }
  }
  .swiper-slide {
    height: initial;
  }
  .thumb-wrapper {
    margin: 0;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    img {
      padding: 5px;
      width: 100%;
      height: auto;
      max-width: 140px;
      max-height: 140px;
      border: 1px solid #d5d5d5;
      border-radius: 8px;
      &.selected {
        border-color: var(--v-primary-lighten1);
      }
    }
  }
  .swiper-button-next {
    right: -10px;
    &:after {
      font-family: "ali";
      content: "\63";
    }
  }
  .swiper-button-prev {
    left: -10px;
    &:after {
      font-family: "ali";
      content: "\66";
    }
  }
}
.swiper-button-disabled {
  opacity: 0.5 !important;
  transition: opacity 0.2s ease-in-out;
}

.zoom-dialog {
  background-color: black;
}
.zoom-dialog-content {
  .pinch-zoom-wrapper {
    background-color: white !important;
    max-width: 95% !important;
    margin: 0 auto;
    img {
      border: 1px solid var(--v-grey-lighten3);
    }
  }
}
.zoom-swiper-wrapper {
  .swiper-slide {
    img {
      padding: 5px;
      width: 80px;
      height: 80px;
      border: 1px solid transparent;
      &.selected {
        border-color: #e6e6e6;
      }
    }
  }
}
</style>
<script>
import PinchZoom from "vue-pinch-zoom";

export default {
  name: "ProductImages",
  props: {
    images: { type: Array, required: true },
    name: { type: String, required: false }
  },
  components: { PinchZoom },
  data() {
    return {
      selectedImage: {},
      dialog: false,
      swiperOptionThumbs: {
        direction: "vertical",
        slidesPerView: "auto",
        slidesPerGroup: 1,
        spaceBetween: 10,
        watchOverflow: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  mounted() {
    this.selectedImage = this.images[0];
  }
};
</script>
